import { useState } from "react";
import "./navbar.css";

import { Link, useMatch, useResolvedPath } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import Web from "../../assets/images/web.png";


export default function NavBarComponent() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navigation">
      <div className="logoWrapper">
        <img src={Logo} alt="logo" className="img" />
      </div>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <CustomLink
            to="/"
            onClick={() => {
              setIsNavExpanded(false);
            }}
          >
            Home
          </CustomLink>
          <CustomLink
            to="/services"
            onClick={() => {
              setIsNavExpanded(false);
            }}
          >
            Services
          </CustomLink>
          <CustomLink
            to="/privacy"
            onClick={() => {
              setIsNavExpanded(false);
            }}
          >
            Privacy
          </CustomLink>
          <CustomLink
            to="/contact"
            onClick={() => {
              setIsNavExpanded(false);
            }}
          >
            Contact
          </CustomLink>

        <li>
        <a
                href="http://play.jumbly.org/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <button className="button2" style={{marginRight: "25px", marginTop: "-1rem"}} >
                  <div>
                    <img
                    alt=""
                      src={Web}
                      style={{ width: 30, height: 30, marginRight: 10}}
                    />
                  </div>
                  <div>
                    <b className="textButtonStyle3">Play on the</b>
                    <br></br>
                    <text className="textButtonStyle4">Web</text>
                  </div>
                </button>
              </a>
        </li>
        </ul>
      </div>
    </nav>
  );
}

function CustomLink({ to, onClick, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props} onClick={onClick}>
        {children}
      </Link>
    </li>
  );
}
