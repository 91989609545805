import NavBarComponent from "../../components/NavBar";
import "./styles.css";
import BackgroundImage from "../../assets/BackgroundImage.png";
import Phone from "../../assets/phone.png";
import TextInput from "../../components/TextInput";
import Textarea from "../../components/Textarea";
import { useState } from "react";
import { CONTACT_URL } from "../../utils/constants";
import Footer from "../../components/Footer"


const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const didTapOnSubmit = () => {
    setLoading(true)
    let params = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    fetch(CONTACT_URL, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false)
        setName("")
        setPhone("")
        setMessage("")
        setEmail("")
        alert(data.message)
        

      }
      )
  };

  return (
    <div className="mainDiv">
    <div className="contactContainer">
      <div className="rowWrapper1">
        <div className="buttonContainer">
          <button className="buttonStyle">
            <img src={Phone} className="phoneIconStyle" />
          </button>
        </div>
     
       <div className="titleWrapper">
          <b className="textStyle">Talk to us<br></br>
          
          </b>
          <b className="subTitle">
            Send us your message and we'll be happy to reply
          </b>
        </div> 
      </div>
      <form>
        <div className="rowWrapper">
          <div>
            <span className="textStyle1">Your Information</span>
            <div className="inputContainer">
              <TextInput
                label="Name"
                value={name}
                handleChange={(e) => setName(e.target.value)}
              />
              <TextInput
                label="Email"
                value={email}
                handleChange={(e) => setEmail(e.target.value)}
              />
              <TextInput
                label="Phone"
                value={phone}
                handleChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>

          <div className="textAreaWrapper">
            <div>
              <span className="textStyle1">Leave your message</span>
            </div>
            <div className="inputContainer">
              <Textarea
                label="Message"
                value={message}
                handleChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="submitButtonContainer">
              <button className="submitButton" onClick={didTapOnSubmit} disabled={isLoading}>
                <span className="buttonText">Submit</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
   
    <Footer />

    </div>
  );
};
export default Contact;
