import FooterLine from "../../assets/images/footerline.png";
import Logo from "../../assets/Logo.png";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer" >
      <img
        src={Logo}
        style={{ objectFit: "contain", width: 200, height: 100 }}
      />
      <ul>
        <li>
          <a href="/">Home</a>
          <a href="/services" to="">
            Services
          </a>
          <a href="/privacy">Privacy</a>
          <a href="/contact">Contact</a>
        </li>
      </ul>
      <img src={FooterLine} style={{width:"100%"}} />
      {`©iLeaf solutions pvt ltd`}
    </footer>
   
  );
};

export default Footer;
