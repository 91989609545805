import "./styles.css";
import Mobile from "../../assets/images/mobile.png";
import "./styles.css";
import Apple from "../../assets/images/apple.png";
import Play from "../../assets/images/play.png";
import Words from "../../assets/images/words.png";
import Classic from "../../assets/images/Classic.png";
import Hands from "../../assets/images/hands.png";
import Tile from "../../assets/images/title.png";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <div className="container">
      <div className="background1">
        <div className="rowWrapperContainer">
          <div className="columnWrapper first">
            <img src={Tile} className="responsiveImageTitle" alt="title"/>
            <span className="contentTitleText1">
              You are on the right place,
            </span>
            <span className="contentText1">
              We offer you a game called Jumbly - Word Game. Jumbly is a
              fast-paced word puzzle game that will have you hooked for hours on
              end. You can play for free, but the real fun starts when you start
              winning coins and climbing up our leader board. Download now and
              start puzzling your way to the top!
            </span>

            <div className="buttonWrapper">
           

              <a
                href="https://apps.apple.com/us/app/jumbly-word-game/id1635929706"
                target="_blank"
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <button className="button1">
                  <div>
                    <img
                    alt=""
                      src={Apple}
                      style={{ width: 30, height: 35, padding: 10,}}
                    />
                  </div>
                  <div>
                    <b className="textButtonStyle1">Download on the</b>
                    <br></br>
                    <text className="textButtonStyle2">App Store</text>
                  </div>
                </button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.ileafsolutions.jumblyapp"
                target="_blank"
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <button className="button2">
                  <div>
                    <img
                      src={Play}
                    alt=""

                      style={{ width: 30, height: 35, marginRight: 10}}
                    />
                  </div>
                  <div>
                    <b className="textButtonStyle3">Download on the</b>
                    <br></br>
                    <text className="textButtonStyle4">Play Store</text>
                  </div>
                </button>
              </a>
            </div>
          </div>
          <div className="imgWrapper second">
            <img src={Mobile} alt="mobile" className="responsiveImage"/>
          </div>
        </div>
      </div>
      <div className="background2">
        <div className="rowWrapperContainer">
          <div className="imgWrapper">
            <img src={Words} alt="word" className="responsiveImage" />
          </div>
          <div className="columnWrapper2 ">
            <span
              className="contentTitleText2"
              style={{ fontFamily: "Slackey" }}
            >
              Word Fever
            </span>
            <span className="contentText2">
              In Word Fever, players are presented with jumbled letters and must
              find as many words as possible within a set time limit. The words
              can range from 4 to 7 letters in length. The objective of the game
              is to find as many words as possible before the time runs out,
              using your vocabulary and word-finding skills. Whether you're a
              word enthusiast or just looking for a fun and challenging way to
              pass the time, Word Fever is a great choice.
            </span>
          </div>
        </div>
      </div>
      <div className="background3">
        <div className="rowWrapperContainer">
          <div className="columnWrapper first">
            <span
              className="contentTitleText3"
              style={{ fontFamily: "Slackey" }}
            >
              Classic Game
            </span>
            <span className="contentText3">
              In Classic Game, the goal is to earn the highest score by creating
              words of varying lengths and difficulty levels. This mode is
              perfect for players who enjoy a traditional word game experience
              and want to test their vocabulary skills. With multiple rounds and
              varying difficulty levels, classic mode is sure to provide hours
              of entertainment for word lovers everywhere.
            </span>
          </div>
          <div className="imgWrapper second">
            <img src={Classic} alt="classic" className="responsiveImage" style={{paddingTop:30,paddingBottom:30}}/>
          </div>
        </div>
      </div>
      <div className="background4">
        <div className="rowWrapperContainer">
          <div className="imgWrapper">
            <img src={Hands} alt="hands" className="responsiveImage" />
          </div>
          <div className="columnWrapper2">
            <span
              className="contentTitleText4"
              style={{ fontFamily: "Slackey" }}
            >
              Word Hang
            </span>
            <span className="contentText4">
              Word Hang is a thrilling and challenging game that puts your
              vocabulary skills to the test. In this game, you have three
              chances to guess the secret word before it's too late. But don't
              worry, you're not alone! The game provides you with three helpful
              hints to guide you along the way. Can you guess the word before
              you hang to death? Play Word Hang and find out!
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
