import React, { useState } from 'react';
import './styles.css';

function TextInput({ type = 'text', label,value,handleChange }) {


  return (
    <div className="input-container">
      <input type={type} value={value} onChange={handleChange&&handleChange} />
      <label className={value && 'filled'}>
        {label}
      </label>
    </div>
  );
}

export default TextInput;