import React, { useState } from "react";
import "./styles.css";

function Textarea({ type = "text", label,value ,handleChange}) {
  

  return (
    <div className="textarea-container">
      <textarea
        className="textarea"
        type={type}
        value={value}
        onChange={handleChange&&handleChange}
      />
      <label className={value && "filled"}>{label}</label>
    </div>
  );
}

export default Textarea;
